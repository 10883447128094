@import url('https://fonts.googleapis.com/icon?family=Material+Icons');


body{
  background: rgb(254, 254, 254);
}

#strategy-container {
  display: flex;
}

#idea-backlog{
  min-height: 200px;
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow-x: hidden;
  max-height: 200px;
  overflow-y: scroll;
  position: relative;
  /* background-image: linear-gradient(rgba(0, 0, 0, 0), rgb(255, 255, 255)); */

}

/* #idea-backlog::before{
  bottom: 0;
  left: 0;
  top: 0;
  right:0;
  pointer-events: none;
  position: absolute;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgb(255, 255, 255));
  content: "";
  width: 100%;
  z-index: 1;
  height: 100%;
} */

 

#strategy-container.list .strategy-grid {
  
  grid-template-columns: 2fr 2fr 2fr;
 
}

#strategy-container.list .strategy-grid-item:nth-child(3) {
  display: none;
}

#strategy-container.list .strategy-masthead {
 
}
.strategy-masthead {
  width: calc(20vw - 30px);
  width: 375px;
  margin: 15px;
  
}

.strategy-masthead h1{
   
  margin: 0 0 20px 0;
  font-size: 45px;
  width: 70%;
  position: relative;
  line-height: 40px;

}
.strategy-masthead h1:after{
   content: "";
   position: absolute;
   top: 105%;
   left:0;
   height: 10px;
   width: 75%;
   background-color: #ffe01b;
}
 
.strategy-grid {
  width: calc(90vw - 30px);
  display: grid;
  grid-template-columns: 2fr 2fr;
  box-sizing: border-box;
  height: calc(100vh - 30px);
  margin: 15px 15px;
  gap: 15px 15px;
}

.strategy-grid-item {
  background: rgb(254, 254, 254);
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  min-width: 200px;

}
 
 
.add-item{
  margin: 20px;
  cursor: pointer;
  display: block;
  width: 25px;
  height: 25px;
 
}
@keyframes slidein {
  from {
    transform: translateX(10px);
    opacity: 0.5;
  }
  to {
    transform: translateX(0);
    opacity: 1;

  }
}

.strategy-grid-item-list {
  margin: 10px;
  margin: 0;
  padding: 0 20px 20px 20px;
  display: flex;
  flex-wrap: wrap;
  min-height: 100px;
  align-items:flex-start;
  align-content:flex-start;
  height: 100%;
  flex-direction: column;
 
}
 
.strategy-grid-item-list-item:nth-child(odd){
  margin-left: 0;
}
.strategy-grid-item-list-item{
  width: calc(70% - 30px);
  list-style: none;
  margin: 10px 0 10px 10px;
  padding: 10px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  
}
.material-icons{
  line-height: 2;
}
.strategy-grid-text{
  margin: 5px;
  padding: 10px;
  mix-blend-mode: multiply;
  outline: none;
}
.strategy-grid-text:focus{
  border-bottom: solid 1px #ffe01b;

}
@media (min-width: 320px) and (max-width: 480px) {
  .strategy-grid,
  #strategy-container.list .strategy-grid {
    grid-template-columns: 2fr;
    width: calc(100vw - 30px);
  }
  #strategy-container {
    flex-direction: column;
  }
  .strategy-masthead {
    min-width: 100px;
    width: calc(100% - 30px);
    height: auto;
    border-right: none;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
}

@media (min-width: 768px) and (max-width: 1024px) {
  .strategy-grid,
  #strategy-container.list .strategy-grid {
    grid-template-columns: 2fr;
   }
 
}
@media (min-width: 481px) and (max-width: 767px) {
  .strategy-grid,
  #strategy-container.list .strategy-grid {
    grid-template-columns: 2fr;
    width: calc(100vw - 30px);
  }
  #strategy-container {
    flex-direction: column;
  }
  .strategy-masthead {
    min-width: 100%;
    max-width: 300px;
    width: 100%;
    height: auto;
    border-right: none;
   
  }
}
